<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Update IncomeShipment</v-card-title>
    <v-row>

      <v-col cols="6"><v-text-field dense outlined label="Name" v-model="item.name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Description" v-model="item.description"/></v-col>

    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processUpdate">Update</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'UpdateIncomeShipment',
  props: ['selectedItem', 'onUpdated', 'onCancel'],
  data: () => ({
    item: {}
  }),
  methods: {
    ...mapActions({
      updateItem: 'income_shipment/update',
    }),
    processUpdate() {
      this.updateItem(this.item).then(() => {
        this.onUpdated();
      });
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
